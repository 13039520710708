import { Suspense } from 'react';
import type { ReactNode } from 'react';

import { ChunkLoadErrorBoundary, ErrorBoundary } from '@margobank/components/error-boundaries';
import { useIntl } from '@margobank/components/intl';
import Loader from '@margobank/components/Loader';
import { EmptyState } from '@margobank/components/page';
import { StickerIcon } from '@margobank/components/Sticker';
import { SecondaryButton } from '@memobank/ui';

type Props = {
  children: ReactNode;
};

const SuspenseWithErrorBoundary = ({ children }: Props) => {
  const { t } = useIntl();

  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <ErrorBoundary>
      <ChunkLoadErrorBoundary
        fallback={
          <EmptyState
            body={t('customer.errorPage.network.body')}
            buttonElement={
              <SecondaryButton onClick={handleRetry}>
                {t('customer.errorPage.network.retry')}
              </SecondaryButton>
            }
            stickerElement={<StickerIcon icon="large/cross" size="x-large" variant="red" />}
            title={t('customer.errorPage.network.title')}
          />
        }
      >
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </ChunkLoadErrorBoundary>
    </ErrorBoundary>
  );
};

export default SuspenseWithErrorBoundary;
