import { Enum } from '@memobank/utils';

export const HELP_BEACON_ARTICLE = Enum(
  'ACCOUNTING',
  'AUTOMATIC_OUTGOING_COLLECTION_RETRY',
  'BENEFICIARIES_XML',
  'CAPITAL_INCREASE',
  'CARDS_DEFERRED_DEBIT',
  'CARDS_VALIDATION_RULES',
  'CURRENCIES',
  'ENTITY_TYPES',
  'EXPENSES_IMPORT',
  'GROUP_TRANSFER_XML',
  'INSTANT_TRANSFER',
  'OUTGOING_COLLECTIONS',
  'OUTGOING_MANDATE_REQUESTS',
  'OUTGOING_MANDATES',
  'OVERDRAFT',
  'PAIRING_INSTALLATION',
  'PASSWORD',
  'PERMISSIONS',
  'REMUNERATION_INTERESTS',
  'SECONDARY_IBANS',
  'SEPA_MANDATES',
  'SEPA_ZONE',
  'SUPPLIERS_IMPORT',
  'SWIFT_TRANSFERS',
  'TERM_DEPOSITS',
  'TRANSFER_CANCELLATION',
  'TRANSFER_COLLECTION_CALENDAR',
  'TRANSFER_VALIDATION_RULES',
  'TRANSFERS',
  'UBBLE',
);

export const HELP_BEACON_ARTICLE_FR = {
  [HELP_BEACON_ARTICLE.ACCOUNTING]: '388',
  [HELP_BEACON_ARTICLE.AUTOMATIC_OUTGOING_COLLECTION_RETRY]: '373',
  [HELP_BEACON_ARTICLE.BENEFICIARIES_XML]: '172',
  [HELP_BEACON_ARTICLE.CAPITAL_INCREASE]: '328',
  [HELP_BEACON_ARTICLE.CARDS_DEFERRED_DEBIT]: '321',
  [HELP_BEACON_ARTICLE.CARDS_VALIDATION_RULES]: '394',
  [HELP_BEACON_ARTICLE.CURRENCIES]: '360',
  [HELP_BEACON_ARTICLE.ENTITY_TYPES]: '133',
  [HELP_BEACON_ARTICLE.EXPENSES_IMPORT]: '390',
  [HELP_BEACON_ARTICLE.GROUP_TRANSFER_XML]: '179',
  [HELP_BEACON_ARTICLE.INSTANT_TRANSFER]: '175',
  [HELP_BEACON_ARTICLE.OUTGOING_COLLECTIONS]: '331',
  [HELP_BEACON_ARTICLE.OUTGOING_MANDATE_REQUESTS]: '381',
  [HELP_BEACON_ARTICLE.OUTGOING_MANDATES]: '329',
  [HELP_BEACON_ARTICLE.OVERDRAFT]: '155',
  [HELP_BEACON_ARTICLE.PAIRING_INSTALLATION]: '264',
  [HELP_BEACON_ARTICLE.PASSWORD]: '241',
  [HELP_BEACON_ARTICLE.PERMISSIONS]: '236',
  [HELP_BEACON_ARTICLE.REMUNERATION_INTERESTS]: '299',
  [HELP_BEACON_ARTICLE.SECONDARY_IBANS]: '308',
  [HELP_BEACON_ARTICLE.SEPA_MANDATES]: '197',
  [HELP_BEACON_ARTICLE.SEPA_ZONE]: '174',
  [HELP_BEACON_ARTICLE.SUPPLIERS_IMPORT]: '389',
  [HELP_BEACON_ARTICLE.SWIFT_TRANSFERS]: '347',
  [HELP_BEACON_ARTICLE.TERM_DEPOSITS]: '363',
  [HELP_BEACON_ARTICLE.TRANSFER_CANCELLATION]: '182',
  [HELP_BEACON_ARTICLE.TRANSFER_COLLECTION_CALENDAR]: '188',
  [HELP_BEACON_ARTICLE.TRANSFER_VALIDATION_RULES]: '353',
  [HELP_BEACON_ARTICLE.TRANSFERS]: '176',
};

export const HELP_BEACON_LABELS = [
  'addReply',
  'addYourMessageHere',
  'answer',
  'ask',
  'attachAFile',
  'attachmentErrorText',
  'attachmentSizeErrorText',
  'beaconButtonChatMinimize',
  'beaconButtonChatOpen',
  'beaconButtonClose',
  'cantFindAnswer',
  'chatButtonDescription',
  'chatButtonLabel',
  'continueEditing',
  'customFieldsValidationLabel',
  'defaultMessageErrorText',
  'docsArticleErrorText',
  'docsSearchEmptyText',
  'docsSearchErrorText',
  'emailContinueConversation',
  'emailCopyOfDiscussion',
  'emailEndedLineItem',
  'emailGreeting',
  'emailHeading',
  'emailJoinedLineItem',
  'emailLabel',
  'emailValidationLabel',
  'emailYou',
  'escalationQuestionFeedback',
  'escalationQuestionFeedbackNo',
  'escalationQuestionFeedbackYes',
  'escalationSearchText',
  'escalationSearchTitle',
  'escalationTalkText',
  'escalationTalkTitle',
  'escalationThanksFeedback',
  'escalationWhatNext',
  'firstAFewQuestions',
  'getInTouch',
  'history',
  'howCanWeHelp',
  'justNow',
  'lastUpdated',
  'mayNotBeEmpty',
  'messageButtonLabel',
  'messageConfirmationText',
  'messageLabel',
  'messageSubmitLabel',
  'nameLabel',
  'next',
  'noTimeToWaitAround',
  'nothingFound',
  'previousMessageErrorText',
  'previousMessages',
  'received',
  'relatedArticles',
  'responseTime',
  'searchLabel',
  'sendAMessage',
  'sendMessage',
  'subjectLabel',
  'suggestedForYou',
  'tryAgain',
  'tryBroaderTerm',
  'uploadAnImage',
  'viewAndUpdateMessage',
  'waitingForAnAnswer',
  'weAreOnIt',
  'wereHereToHelp',
  'whatMethodWorks',
  'you',
] as const;

export const HELP_BEACON_THEME = Enum('PRIMARY', 'SECONDARY');
