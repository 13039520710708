import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';
import { UserThumbnail } from '@margobank/components/domain/user';
import { DropdownMenu } from '@margobank/components/dropdown';
import { useIntl } from '@margobank/components/intl';
import ThumbnailRow from '@margobank/components/ThumbnailRow';
import { Body, Icon } from '@memobank/ui';

import type { UserDTO } from 'app/auth/types';

import { UserMenuLargeTrigger } from '../_styled';
import UserMenuContent from './UserMenuContent';

type Props = {
  onlyLogout: boolean;
  user: UserDTO;
  variant?: 'dark' | 'light' | 'transparent';
} & (
  | { customer?: AuthenticatedCustomerDTO }
  | {
      grantingFile?: GrantingFileDTO;
    }
);

export const TEST_ID_USER_DROPDOWN_TRIGGER = 'user-dropdown-trigger';

const UserMenuDropdown = ({ onlyLogout, user, variant, ...rest }: Props) => {
  const { formatDisplayName } = useIntl();

  const currentCustomer = 'customer' in rest ? rest.customer : undefined;
  const currentGrantingFile = 'grantingFile' in rest ? rest.grantingFile : undefined;

  const companyName = currentCustomer?.name ?? currentGrantingFile?.name;

  const renderTrigger = ({ ariaProps, isOpen, open, ref }) => (
    <UserMenuLargeTrigger
      {...ariaProps}
      ref={ref}
      active={isOpen}
      data-testid={TEST_ID_USER_DROPDOWN_TRIGGER}
      onClick={open}
      variant={variant}
    >
      <ThumbnailRow noOverflow thumbnail={<UserThumbnail size="medium" user={user} />}>
        <Body bold>{formatDisplayName(user.individual)}</Body>
        <Body ellipsis subdued>
          {companyName}
        </Body>
      </ThumbnailRow>
      <Icon
        icon="medium/chevron_down_small"
        rotate={isOpen ? '-180deg' : '0'}
        subdued={variant === 'dark'}
      />
    </UserMenuLargeTrigger>
  );

  return (
    <DropdownMenu placement="bottom-end" renderTrigger={renderTrigger}>
      <UserMenuContent onlyLogout={onlyLogout} {...rest} />
    </DropdownMenu>
  );
};

export default UserMenuDropdown;
