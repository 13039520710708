import type { ComponentProps } from 'react';

import { useIntl } from '@margobank/components/intl';
import { Body, Column, LinkButton, PrimaryButton, Subheading } from '@memobank/ui';

import unlockDevices from 'assets/images/unlock-devices.svg';
import { useHelpBeacon } from 'components/help-beacon';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';

type Props = Pick<
  ComponentProps<typeof PairingLayout>,
  'closeButton' | 'onPrevious' | 'rightComponent'
> & {
  onConfirm: () => void;
};

const Start = ({ onConfirm, ...rest }: Props) => {
  const { showHelpArticle } = useHelpBeacon();
  const { t } = useIntl();

  return (
    <PairingLayout {...rest} title={t('auth.pairing.title')}>
      <PairingCard>
        <img alt={t('auth.pairing.title')} height="140" src={unlockDevices} width="240" />
        <Column spacing="s1">
          <Subheading>{t('auth.pairing.start.intro')}</Subheading>
          <Body subdued>{t('auth.pairing.start.body')}</Body>
        </Column>
        <PrimaryButton onClick={onConfirm}>{t('auth.pairing.start.confirm')}</PrimaryButton>
        <LinkButton onClick={() => showHelpArticle('PAIRING_INSTALLATION')}>
          {t('auth.pairing.start.checkDevice')}
        </LinkButton>
      </PairingCard>
    </PairingLayout>
  );
};

export default Start;
