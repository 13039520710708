import { useRef, useState } from 'react';

import { getErrorMessage } from '@margobank/components/common/error';
import { handleFormError } from '@margobank/components/form';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@memobank/ui';
import { Enum } from '@memobank/utils';

import { confirmEmailUpdatedVerification, loadUser } from 'app/auth/actions';
import { getApprovalRequestIdFromError } from 'app/auth/helpers';
import type { ConfirmEmailVerificationRequestDTO, UserDTO } from 'app/auth/types';
import { useDispatch } from 'common/store';
import SecondFactorValidationRejected from 'components/SecondFactorValidation/Rejected';
import SecondFactorValidationSuccess from 'components/SecondFactorValidation/Success';

import EmailSecondFactorValidation from './EmailSecondFactorValidation';
import EmailValidation from './EmailValidation';
import Intro from './Intro';

const STEP = Enum('INTRO', 'EMAIL_VALIDATION', 'SECOND_FACTOR_VALIDATION', 'SUCCESS', 'REJECTED');
type Step = Enum<typeof STEP>;

type Props = {
  user: UserDTO;
};

const ValidationEmail = ({ user }: Props) => {
  const dispatch = useDispatch();
  const { t } = useIntl();
  const [currentStep, setCurrentStep] = useState<Step>(STEP.INTRO);

  const approvalRequestIdRef = useRef<string>();
  const validationErrorRef = useRef<string>();
  const verificationCodeRef = useRef<string>();

  const handleAfterSuccess = () => {
    dispatch(loadUser());
  };

  const handleEmailValidationSubmit = ({ code }: ConfirmEmailVerificationRequestDTO) => {
    verificationCodeRef.current = code;
    return tryValidateNewEmail();
  };

  const handleSecondFactorApproved = () => {
    if (!approvalRequestIdRef.current) {
      throw Error('approvalRequestIdRef.current must be defined at this stage');
    }

    tryValidateNewEmail(approvalRequestIdRef.current).catch((error) => {
      validationErrorRef.current = getErrorMessage(error);
      setCurrentStep(STEP.INTRO);
    });
  };

  const handleSecondFactorError = () => {
    validationErrorRef.current = t('auth.secondFactorValidation.expiredError');
    setCurrentStep(STEP.INTRO);
  };

  const handleSecondFactorRejected = () => setCurrentStep(STEP.REJECTED);

  const renderIntro = () => (
    <Intro
      error={validationErrorRef.current}
      onConfirm={() => setCurrentStep(STEP.EMAIL_VALIDATION)}
      user={user}
    />
  );

  const renderRejected = () => (
    <Column flex="1" justifyContent="center">
      <SecondFactorValidationRejected
        onRetry={() => setCurrentStep(STEP.INTRO)}
        title={t('auth.validation.email.rejected.title')}
      />
    </Column>
  );

  const renderSecondFactorValidation = () => {
    if (!approvalRequestIdRef.current) {
      throw Error('approvalRequestIdRef.current must be present');
    }

    return (
      <EmailSecondFactorValidation
        approvalRequestId={approvalRequestIdRef.current}
        onApproved={handleSecondFactorApproved}
        onError={handleSecondFactorError}
        onRejected={handleSecondFactorRejected}
        user={user}
      />
    );
  };

  const renderEmailValidation = () => (
    <EmailValidation
      onPrevious={() => setCurrentStep(STEP.INTRO)}
      onSubmit={handleEmailValidationSubmit}
      user={user}
    />
  );

  const renderSuccess = () => (
    <Column flex="1" justifyContent="center">
      <SecondFactorValidationSuccess
        body={t('auth.validation.email.success.description')}
        onConfirm={handleAfterSuccess}
        title={t('auth.validation.email.success.title')}
      />
    </Column>
  );

  const tryValidateNewEmail = (approvalRequestId?: string) => {
    if (!verificationCodeRef.current) {
      throw Error('verificationCodeRef.current must be present');
    }

    return dispatch(
      confirmEmailUpdatedVerification(user.id, verificationCodeRef.current, approvalRequestId),
    ).then(
      (_success) => {
        setCurrentStep(STEP.SUCCESS);
      },
      (error) => {
        const id = getApprovalRequestIdFromError(error);
        if (id) {
          approvalRequestIdRef.current = id;
          setCurrentStep(STEP.SECOND_FACTOR_VALIDATION);
        } else {
          handleFormError(error);
        }
      },
    );
  };

  return (
    <>
      {currentStep === STEP.INTRO && renderIntro()}
      {currentStep === STEP.EMAIL_VALIDATION && renderEmailValidation()}
      {currentStep === STEP.SECOND_FACTOR_VALIDATION && renderSecondFactorValidation()}
      {currentStep === STEP.SUCCESS && renderSuccess()}
      {currentStep === STEP.REJECTED && renderRejected()}
    </>
  );
};

export default ValidationEmail;
