import type { CardUsage } from '@margobank/components/domain/cards';
import { CARD_SETTLEMENT_TYPE } from '@margobank/components/domain/cards';
import type { MandateGroup } from '@margobank/components/domain/mandates';
import type { OperationType } from '@margobank/components/domain/operations/types';

import type { AccountDetailsSection } from 'app/legacy-customer/Accounts/Account/Details';
import type { CapitalAccountDetailsSection } from 'app/legacy-customer/Accounts/CapitalAccount/Details';

import { CUSTOMER_ROUTE } from '../app/legacy-customer/menu';

export const accountDetailsPath = (
  hash: string,
  accountId: string,
  section?: AccountDetailsSection,
) => {
  return `/${hash}/account/${accountId}/details/${section || ''}`;
};

export const accountIBANDetailsPath = (
  hash: string,
  accountId: string,
  secondaryIBANId: string,
) => {
  return `/${hash}/account/${accountId}/ibans?secondaryIBANId=${secondaryIBANId}`;
};

export const accountIBANsPath = (hash: string, accountId: string) => {
  return `/${hash}/account/${accountId}/ibans`;
};

export const accountPath = (hash: string, accountId: string) => {
  return `/${hash}/account/${accountId}`;
};

export const accountingExportsPath = (hash: string) => {
  return `${accountingPath(hash)}/operations/exported`;
};

export const accountingOperationsPath = (hash: string, state: string) => {
  return `${accountingPath(hash)}/operations/${state}`;
};

const accountingPath = (hash: string) => {
  return `/${hash}/accounting`;
};

export const accountingSupplierMappingPath = (hash: string, id: string) => {
  return `${accountingPath(hash)}/suppliers/list?mappingId=${id}`;
};

export const allCurrentAccountsPath = (hash: string) => {
  return `/${hash}/account/all`;
};

export const apiApplicationsPath = (hash: string) => {
  return `/${hash}/${CUSTOMER_ROUTE.API}/applications`;
};

export const apiApplicationDetailsPath = (hash: string, applicationId: string) => {
  return `${apiApplicationsPath(hash)}/${applicationId}`;
};

export const apiApplicationWebhookDetailsPath = (
  hash: string,
  applicationId: string,
  webhookId: string,
) => {
  return `${apiApplicationDetailsPath(hash, applicationId)}/webhooks/${webhookId}`;
};

export const beneficiaryDetailsPath = (hash: string, beneficiaryId: string) => {
  return `/${hash}/payment/beneficiaries?beneficiaryId=${beneficiaryId}`;
};

export const boosterAccountPath = (hash: string) => {
  return `/${hash}/account/savings`;
};

export const capitalAccountDetailsPath = (
  hash: string,
  capitalAccountId: string,
  section?: CapitalAccountDetailsSection,
) => {
  return `${capitalAccountPath(hash, capitalAccountId)}/details/${section ?? ''}`;
};

export const capitalAccountPath = (hash: string, accountId: string) => {
  return `/${hash}/account/capital/${accountId}`;
};

export const cardOperationPath = (hash: string, cardId: string, cardOperationId: string) => {
  return `${cardsListPath(
    hash,
  )}/operations?cardOperationId=${cardOperationId}&selectedCardId=${cardId}`;
};

export const cardPath = (hash: string, cardId: string) => {
  return `/${hash}/cards/all/${cardId}`;
};

export const cardRenewalPath = (hash: string, cardId: string) => {
  return `/${hash}/cards/all/${cardId}/renewal`;
};

export const cardSettingsPath = (hash: string, cardId: string, section?: string) => {
  return `/${hash}/cards/all/${cardId}/settings/${section || ''}`;
};

export const cardsDeferredListPath = (hash: string, usage: CardUsage) => {
  return `/${hash}/cards/all/${usage === 'UNIQUE' ? 'single-usage' : 'regular'}?settlementType=${
    CARD_SETTLEMENT_TYPE.DEFERRED
  }`;
};

export const cardsListPath = (hash: string) => {
  return `/${hash}/cards`;
};

export const crsFatcaPath = (hash: string) => {
  return `/${hash}/crs-fatca`;
};

const companySettingsPath = (hash: string) => {
  return `/${hash}/company-settings`;
};

export const customerEbicsDetailsPath = (hash: string, ebisConnectionId?: string) => {
  return `${companySettingsPath(hash)}/ebics/${ebisConnectionId || ''}`;
};

export const debtPurchaseDetailsPath = (hash: string, debtPurchaseId: string) => {
  return `/${hash}/financing/details/debt-purchase/${debtPurchaseId}`;
};

export const documentsPath = (hash: string) => {
  return `/${hash}/documents`;
};

export const financingPath = (hash: string) => {
  return `/${hash}/financing`;
};

export const getRouteMatchRegex = (routes: string[]) =>
  routes.map((route) => route + '.*').join('|');

export const loanDetailsPath = (hash: string, loanId: string) => {
  return `/${hash}/financing/details/${loanId}`;
};

export const mandateCollectionsPath = (hash: string, mandateId: string) => {
  return `/${hash}/collections/${mandateId}`;
};

export const mandateDetailsPath = (hash: string, mandateGroup: MandateGroup, mandateId: string) => {
  return `/${hash}/collections/${mandateGroup}?mandateId=${mandateId}`;
};

export const outgoingCollectionPath = (
  hash: string,
  type: 'history' | 'scheduled',
  operationId: string,
  operationType: OperationType,
  currentAccountId: string,
) => {
  return `/${hash}/outgoing-collections/${type}?operationId=${operationId}&operationType=${operationType}&operationCurrentAccountId=${currentAccountId}`;
};

export const outgoingMandateCollectionsPath = (hash: string, mandateId: string) => {
  return `/${hash}/outgoing-collections/mandates/${mandateId}`;
};

/**
 * Returns the path to the screen listing all outgoing-collections mandates in the background,
 * with the panel showing the details of a given mandate being open by default.
 *
 * @param hash The hash of the customer workspace.
 * @param mandateId The ID of the mandate that must be opened automatically.
 * @returns The relative path of the route listing all mandates with one mandate panel opened.
 */
export const outgoingMandateDetailsPath = (hash: string, mandateId: string) => {
  return `/${hash}/outgoing-collections/mandates?mandateId=${mandateId}`;
};

export const recurringTransferPath = (hash: string) => {
  return `/${hash}/payment/recurring`;
};

export const remunerationPath = (hash: string, currentAccountId?: string) => {
  return `/${hash}/account/${currentAccountId || 'all'}/remuneration`;
};

export const secondaryIBANPath = (
  hash: string,
  currentAccountId: string,
  secondaryIBANId: string,
) => {
  return `/${hash}/account/${currentAccountId}/ibans?secondaryIBANId=${secondaryIBANId}`;
};

export const subscriptionDetailsPath = (hash: string) => {
  return `/${hash}/company-settings/subscription`;
};

export const termDepositOperationPath = (hash: string, termDepositId: string) => {
  return `${termDepositSummaryPath(hash, termDepositId)}/operations`;
};

export const termDepositSummaryPath = (hash: string, termDepositId: string) => {
  return `${termDepositsPath(hash)}/${termDepositId}`;
};

export const termDepositsPath = (hash: string) => {
  return `/${hash}/term-deposits`;
};

export const transferRequestDetailsPath = (
  hash: string,
  operationId: string,
  currentAccountId: string,
) => {
  return `/${hash}/transfer-requests/all?transferRequestCurrentAccountId=${currentAccountId}&transferRequestId=${operationId}`;
};

const usersPath = (hash: string) => {
  return `/${hash}/team/users`;
};

export const userEbicsDetailsPath = (hash: string, ebisConnectionId?: string) => {
  return `${userSettingsPath(hash)}/ebics/${ebisConnectionId || ''}`;
};

export const userDetailsPath = (hash: string, userId = '') => {
  return `${usersPath(hash)}/active/${userId}`;
};

export const userSettingsPath = (hash: string) => {
  return `/${hash}/user-settings`;
};
