import { createStorage } from '@memobank/utils';

import type { GeoTesting } from './types';

const LOCAL_STORAGE_KEY = 'geoTesting';

const storage = createStorage<GeoTesting>(LOCAL_STORAGE_KEY);

/**
 * This helps simulating connections from abroad using the local storage.
 *
 * ⚠️ It must be used **for testing purposes only**, ie **not** on
 * the production environment.
 *
 * To test from the browser, run the following in the console:
 *
 * ```js
 * window.localStorage.setItem('geoTesting', JSON.stringify({ countryCode: 'FR' }));
 * ```
 *
 * To stop tests, run the following in the console:
 *
 * ```
 * window.localStorage.removeItem('geoTesting');
 * ```
 */
export const loadGeoTesting = (): GeoTesting => {
  const geoTesting = storage.get();
  return geoTesting || {};
};
