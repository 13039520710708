import { lighten, rem } from 'polished';

import { css, styled } from '@memobank/style';
import { Column, Heading, PrimaryButton, Row, Subheading } from '@memobank/ui';

type AbsoluteTextContainerProps = {
  variant?: 'apple-pay';
};
export const AbsoluteTextContainer = styled(Column)<AbsoluteTextContainerProps>`
  position: absolute;
  top: 70%;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ theme, variant }) =>
    variant === 'apple-pay' &&
    css`
      margin-top: -${theme.spacing.s2};
    `};
`;

export const CenteredContent = styled(Column)`
  max-width: ${({ theme }) => theme.metric.authFormContainer};
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ColumnLeft = styled(Column)`
  overflow: auto;
  flex: 2;
`;

export const ColumnRight = styled(Column)`
  position: relative;
  flex: 3;
`;

type CoverImageProps = {
  image: string;
};

const PlainButton = styled(PrimaryButton)`
  padding-top: ${({ theme }) => theme.spacing.s1_5};
  padding-bottom: ${({ theme }) => theme.spacing.s1_5};
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: ${({ theme }) => theme.lineHeight.large};
  min-width: ${rem('240px')};
  justify-content: center;

  &:disabled {
    opacity: 0.2;
  }
`;

export const CoverImage = styled(Column)<CoverImageProps>`
  flex: 1;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: 50% 50%;
`;

export const BlackButton = styled(PlainButton)`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.black};

  &:not(:disabled) {
    &:hover {
      background-color: ${({ theme }) => lighten(0.15, theme.color.black)};
    }

    &:active {
      background-color: ${({ theme }) => lighten(0.22, theme.color.black)};
    }
  }
`;

export const FullScreenRow = styled(Row)`
  height: 100%;
  justify-content: center;
`;

export const HeadingsColumn = styled(Column)`
  ${Heading},
  ${Subheading} {
    font-weight: ${({ theme }) => theme.fontWeight.normal};
  }
`;

export const PaddingWrapper = styled(Column).attrs({ spacing: 's6' })`
  flex: 1 0 auto;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.s6} ${theme.spacing.s4}`};
`;

export const UserDropdownWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.s6};
  right: ${({ theme }) => theme.spacing.s4};
`;
