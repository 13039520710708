import { ActionBar } from '@margobank/components/form';
import { useIntl } from '@margobank/components/intl';
import Modal from '@margobank/components/Modal';
import { useSnackbar } from '@margobank/components/snackbar';
import { Body, Column, PrimaryButton, SecondaryButton, Subheading } from '@memobank/ui';

import { useRequestEmailUpdatedVerification } from 'app/auth/mutations';
import { useHelpBeacon } from 'components/help-beacon';

type Props = {
  email: string;
  isOpen: boolean;
  onRequestClose: () => void;
  userId: string;
};

const ResendEmailConfirmationModal = ({ email, isOpen, onRequestClose, userId }: Props) => {
  const { openMessageBox } = useHelpBeacon();
  const { t } = useIntl();
  const { displaySuccess } = useSnackbar();

  const [requestEmailUpdatedVerification, { errorMessage, isLoading }] =
    useRequestEmailUpdatedVerification();

  const handleResendEmail = () => {
    requestEmailUpdatedVerification({ userId }).then(() => {
      displaySuccess(t('common.snackbar.emailMessageSent'));
      onRequestClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} withCloseButton>
      <Column spacing="s3">
        <Column spacing="s1_5">
          <Subheading>{t('auth.validation.email.resendModal.title')}</Subheading>
          <Body>
            {t('auth.validation.email.resendModal.body1', {
              em: (chunk) => (
                <Body bold highlighted inline>
                  {chunk}
                </Body>
              ),
              email,
            })}
          </Body>
          <Body>{t('auth.validation.email.resendModal.body2')}</Body>
        </Column>
        <ActionBar error={errorMessage}>
          <SecondaryButton
            onClick={() =>
              openMessageBox({
                subject: t('common.helpBeacon.resendEmail.subject'),
                text: t('common.helpBeacon.resendEmail.text', {
                  email,
                }),
              })
            }
          >
            {t('auth.validation.email.resendModal.reportWrongEmail.text')}
          </SecondaryButton>
          <PrimaryButton isLoading={isLoading} onClick={handleResendEmail}>
            {t('auth.validation.email.resendModal.submit')}
          </PrimaryButton>
        </ActionBar>
      </Column>
    </Modal>
  );
};

export default ResendEmailConfirmationModal;
