import { useRef, useState } from 'react';

import { getErrorMessage } from '@margobank/components/common/error';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@memobank/ui';
import { Enum } from '@memobank/utils';

import { confirmPhoneNumberUpdatedVerification, loadUser } from 'app/auth/actions';
import { getApprovalRequestIdFromError } from 'app/auth/helpers';
import type { ConfirmPhoneNumberVerificationRequestDTO, UserDTO } from 'app/auth/types';
import { useDispatch } from 'common/store';
import SecondFactorValidationRejected from 'components/SecondFactorValidation/Rejected';
import SecondFactorValidationSuccess from 'components/SecondFactorValidation/Success';

import Intro from './Intro';
import PhoneNumberSecondFactorValidation from './PhoneNumberSecondFactorValidation';
import PhoneNumberSMSValidation from './PhoneNumberSMSValidation';

const STEP = Enum('INTRO', 'SMS_VALIDATION', 'SECOND_FACTOR_VALIDATION', 'SUCCESS', 'REJECTED');
type Step = Enum<typeof STEP>;

type Props = {
  onSuccess?: () => void;
  user: UserDTO;
};

const ValidationPhoneNumber = ({ onSuccess, user }: Props) => {
  const dispatch = useDispatch();
  const { t } = useIntl();
  const [currentStep, setCurrentStep] = useState<Step>(STEP.INTRO);

  const approvalRequestIdRef = useRef<string>();
  const validationErrorRef = useRef<string>();
  const verificationCodeRef = useRef<string>();

  const handleAfterSuccess = () => {
    dispatch(loadUser());
    onSuccess?.();
  };

  const handleSMSValidationSubmit = ({ code }: ConfirmPhoneNumberVerificationRequestDTO) => {
    verificationCodeRef.current = code;
    return tryValidateNewPhoneNumber();
  };

  const handleSecondFactorApproved = () => {
    if (!approvalRequestIdRef.current) {
      throw Error('approvalRequestIdRef.current must be defined at this stage');
    }

    tryValidateNewPhoneNumber(approvalRequestIdRef.current).catch((error) => {
      validationErrorRef.current = getErrorMessage(error);
      setCurrentStep(STEP.INTRO);
    });
  };

  const handleSecondFactorError = () => {
    validationErrorRef.current = t('auth.secondFactorValidation.expiredError');
    setCurrentStep(STEP.INTRO);
  };

  const handleSecondFactorRejected = () => setCurrentStep(STEP.REJECTED);

  const renderIntro = () => (
    <Intro
      error={validationErrorRef.current}
      onConfirm={() => setCurrentStep(STEP.SMS_VALIDATION)}
      user={user}
    />
  );

  const renderRejected = () => (
    <Column flex="1" justifyContent="center">
      <SecondFactorValidationRejected
        onRetry={() => setCurrentStep(STEP.INTRO)}
        title={t('auth.validation.phoneNumber.rejected.title')}
      />
    </Column>
  );

  const renderSecondFactorValidation = () => {
    if (!approvalRequestIdRef.current) {
      throw Error('approvalRequestIdRef.current must be present');
    }

    return (
      <PhoneNumberSecondFactorValidation
        approvalRequestId={approvalRequestIdRef.current}
        onApproved={handleSecondFactorApproved}
        onError={handleSecondFactorError}
        onRejected={handleSecondFactorRejected}
        user={user}
      />
    );
  };

  const renderSMSValidation = () => (
    <PhoneNumberSMSValidation
      onPrevious={() => setCurrentStep(STEP.INTRO)}
      onSubmit={handleSMSValidationSubmit}
      user={user}
    />
  );

  const renderSuccess = () => (
    <Column flex="1" justifyContent="center">
      <SecondFactorValidationSuccess
        body={t('auth.validation.phoneNumber.success.description')}
        onConfirm={handleAfterSuccess}
        title={t('auth.validation.phoneNumber.success.title')}
      />
    </Column>
  );

  const tryValidateNewPhoneNumber = (approvalRequestId?: string) => {
    if (!verificationCodeRef.current) {
      throw Error('verificationCodeRef.current must be present');
    }

    return dispatch(
      confirmPhoneNumberUpdatedVerification(
        user.id,
        verificationCodeRef.current,
        approvalRequestId,
      ),
    ).then(
      (_success) => {
        setCurrentStep(STEP.SUCCESS);
      },
      (error) => {
        const id = getApprovalRequestIdFromError(error);
        if (id) {
          approvalRequestIdRef.current = id;
          setCurrentStep(STEP.SECOND_FACTOR_VALIDATION);
        } else {
          throw error;
        }
      },
    );
  };

  return (
    <>
      {currentStep === STEP.INTRO && renderIntro()}
      {currentStep === STEP.SMS_VALIDATION && renderSMSValidation()}
      {currentStep === STEP.SECOND_FACTOR_VALIDATION && renderSecondFactorValidation()}
      {currentStep === STEP.SUCCESS && renderSuccess()}
      {currentStep === STEP.REJECTED && renderRejected()}
    </>
  );
};

export default ValidationPhoneNumber;
