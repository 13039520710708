import { STATUS } from '@memobank/theme';
import { Body, Icon } from '@memobank/ui';

import { getCustomerHomePath } from 'components/routes/helpers';

import { UserMenuItem } from '../../_styled';
import { CustomersMenuIconRow } from '../_styled';

type Props = {
  hash: string;
  isCurrent: boolean;
  name: string;
};

const CustomerItem = ({ hash, isCurrent, name }: Props) => {
  return (
    <UserMenuItem to={getCustomerHomePath(hash)}>
      {isCurrent ? (
        <CustomersMenuIconRow>
          <Body bold>{name}</Body>
          <Icon icon="medium/check_circled_plain" status={STATUS.INFO} />
        </CustomersMenuIconRow>
      ) : (
        <Body bold>{name}</Body>
      )}
    </UserMenuItem>
  );
};

export default CustomerItem;
