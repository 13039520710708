import { createForm, Field } from '@margobank/components/form';
import { useIntl } from '@margobank/components/intl';
import { useModalState } from '@margobank/components/Modal';
import { Body, Column, ErrorMessage, LinkButton, PrimaryButton, Subheading } from '@memobank/ui';

import { getUnverifiedEmail } from 'app/auth/helpers';
import type { ConfirmEmailVerificationRequestDTO, UserDTO } from 'app/auth/types';

import ValidationCard from '../../_parts/ValidationCard';
import ValidationLayout from '../../_parts/ValidationLayout';
import ResendEmailConfirmationModal from './ResendEmailConfirmationModal';

const Form = createForm('auth.validation.emailValidation');

type Props = {
  onPrevious: () => void;
  onSubmit: (values: ConfirmEmailVerificationRequestDTO) => Promise<void>;
  user: UserDTO;
};

const EmailValidation = ({ onSubmit, user, ...rest }: Props) => {
  const { t } = useIntl();
  const [isModalOpen, { closeModal, openModal }] = useModalState();

  const unverifiedEmail = getUnverifiedEmail(user);

  if (!unverifiedEmail) {
    throw Error('unverifiedEmail must be defined');
  }

  return (
    <ValidationLayout
      subtitle={t('auth.validation.email.subtitle.confirm')}
      title={t('auth.validation.email.title')}
      {...rest}
    >
      <Form onSubmit={onSubmit}>
        {(form) => (
          <ValidationCard spacing="s4">
            <Column spacing="s2">
              <Column spacing="s1" textAlign="center">
                <Subheading>{t('auth.validation.email.confirm.title')}</Subheading>
                <Body>
                  {t('auth.validation.email.confirm.subtitle', {
                    em: (chunk) => (
                      <Body bold highlighted inline>
                        {chunk}
                      </Body>
                    ),
                    email: unverifiedEmail,
                  })}
                </Body>
              </Column>
              <Body>{t('auth.validation.email.confirm.instruction')}</Body>
            </Column>
            <Column spacing="s1">
              <Field name="code" placeholder={t('auth.validation.email.confirm.placeholder')} />
              {form.error && <ErrorMessage>{form.error}</ErrorMessage>}
            </Column>
            <Column alignItems="center" spacing="s3">
              <PrimaryButton isLoading={form.submitting} type="submit">
                {t('common.actions.validate')}
              </PrimaryButton>
              <LinkButton onClick={openModal}>
                {t('auth.validation.email.confirm.resendEmail')}
              </LinkButton>
            </Column>
          </ValidationCard>
        )}
      </Form>
      <ResendEmailConfirmationModal
        email={unverifiedEmail}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        userId={user.id}
      />
    </ValidationLayout>
  );
};

export default EmailValidation;
