import { styled } from '@memobank/style';
import { IconRow, Row } from '@memobank/ui';

export const StyledIconRow = styled(IconRow)`
  min-width: 0;
`;

export const StyledUserThumbnail = styled(Row)`
  margin-top: -${({ theme }) => theme.spacing.s0_5};
  margin-bottom: -${({ theme }) => theme.spacing.s0_5};
`;
