import { useDevice } from '@margobank/components/device';
import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';

import { selectUser } from 'app/auth/selectors';
import type { UserDTO } from 'app/auth/types';
import { useSelector } from 'common/store';

import UserMenuDropdown from './_parts/UserMenuDropdown';
import UserMenuModal from './_parts/UserMenuModal';

type Props = {
  onLogout?: () => void;
  onlyLogout?: boolean;
  user?: UserDTO;
  variant?: 'dark' | 'light' | 'transparent';
} & (
  | {
      grantingFile?: GrantingFileDTO;
    }
  | {
      customer?: AuthenticatedCustomerDTO;
    }
);

const UserDropdown = ({ onlyLogout = false, user, variant = 'light', ...rest }: Props) => {
  const { isScreenAtLeastM } = useDevice();

  const reduxLoggedInUser = useSelector(selectUser);
  const displayedUser = user ?? reduxLoggedInUser;

  if (!displayedUser) {
    return null;
  }

  return isScreenAtLeastM ? (
    <UserMenuDropdown onlyLogout={onlyLogout} user={displayedUser} variant={variant} {...rest} />
  ) : (
    <UserMenuModal onlyLogout={onlyLogout} user={displayedUser} {...rest} />
  );
};

export default UserDropdown;
