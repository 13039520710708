import type { ComponentProps, ReactNode } from 'react';

import { useIntl } from '@margobank/components/intl';
import { FullPageModalHeader, FullPageModalLayout } from '@margobank/components/page';
import { Column } from '@memobank/ui';

type Props = {
  children: ReactNode;
  closeButton?: ComponentProps<typeof FullPageModalHeader>['closeButton'];
  onPrevious?: () => void;
  rightComponent?: ReactNode;
  subtitle?: string;
  title: string;
};

const Layout = ({ children, onPrevious, subtitle, ...props }: Props) => {
  const { t } = useIntl();
  return (
    <Column textAlign="center">
      <FullPageModalLayout
        headerElement={
          <FullPageModalHeader
            {...props}
            {...(onPrevious && {
              backButton: { label: t('auth.pairing.back'), onClick: onPrevious },
            })}
            subtitle={subtitle}
          />
        }
      >
        {children}
      </FullPageModalLayout>
    </Column>
  );
};

export default Layout;
