import type { ComponentProps } from 'react';

import { useIntl } from '@margobank/components/intl';
import { Heading } from '@memobank/ui';

import type { UserDTO } from 'app/auth/types';
import SecondFactorValidation from 'components/SecondFactorValidation';

import ValidationCard from '../../_parts/ValidationCard';
import ValidationLayout from '../../_parts/ValidationLayout';

type Props = Omit<ComponentProps<typeof SecondFactorValidation>, 'deviceName' | 'renderTitle'> & {
  user: UserDTO;
};

const LoginSecondFactorValidation = ({ user, ...rest }: Props) => {
  const { t } = useIntl();

  const { id: userId, pairedDevice } = user;

  if (!pairedDevice) {
    throw Error('pairedDevice must be defined at this point');
  }

  return (
    <ValidationLayout
      subtitle={t('auth.validation.phoneNumber.subtitle.secondFactorValidation')}
      title={t('auth.validation.phoneNumber.title')}
    >
      <ValidationCard>
        <SecondFactorValidation
          {...rest}
          deviceName={pairedDevice.name}
          padding={null}
          renderTitle={({ deviceName }) => (
            <Heading>
              {t('auth.validation.phoneNumber.secondFactorValidation.title', { deviceName })}
            </Heading>
          )}
          userId={userId}
        />
      </ValidationCard>
    </ValidationLayout>
  );
};

export default LoginSecondFactorValidation;
