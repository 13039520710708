import { transparentize } from 'polished';

import { DropdownMenu } from '@margobank/components/dropdown';
import { borderedStyle, css, mediaQuery, styled } from '@memobank/style';
import { ButtonBase, Row } from '@memobank/ui';

type UserDropdownButtonProps = {
  active?: boolean;
  variant?: 'dark' | 'light' | 'transparent';
};

export const UserMenuItem = styled(DropdownMenu.Item)`
  flex: 1;
  justify-content: space-between;

  ${mediaQuery({ max: 'm' })(css`
    padding: ${({ theme }) => `${theme.spacing.s2} ${theme.spacing.s3}`};
  `)}
`;

export const UserMenuLargeTrigger = styled(Row).attrs({
  as: ButtonBase,
  spacing: 's2',
})<UserDropdownButtonProps>`
  ${borderedStyle};
  border-color: transparent;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ theme }) =>
    `${theme.spacing.s1} ${theme.spacing.s1} ${theme.spacing.s1} ${theme.spacing.s2}`};
  margin-top: -${({ theme }) => theme.spacing.s1};
  margin-bottom: -${({ theme }) => theme.spacing.s1};

  ${({ variant }) =>
    (variant === 'dark' || variant === 'transparent') &&
    css`
      color: ${({ theme }) => theme.color.reversedGrayText};
    `}

  &:hover {
    ${({ variant }) =>
      variant === 'dark'
        ? css`
            background-color: ${({ theme }) => theme.color.reversedGrayAccentedBackground};
            color: ${({ theme }) => theme.color.reversedGrayText};
          `
        : variant === 'transparent'
          ? css`
              background-color: ${({ theme }) => transparentize(0.9, theme.color.white)};
              color: ${({ theme }) => theme.color.reversedGrayText};
            `
          : css`
              background-color: ${({ theme }) => theme.color.grayAccentedBackground};
              color: ${({ theme }) => theme.color.blueText};
            `}
  }

  ${({ active, variant }) =>
    active &&
    (variant === 'dark'
      ? css`
          background-color: ${({ theme }) => theme.color.reversedGrayAccentedBackground};
          color: ${({ theme }) => theme.color.grayAccentedBackground};
        `
      : variant === 'transparent'
        ? css`
            background-color: ${({ theme }) => transparentize(0.9, theme.color.white)};
            color: ${({ theme }) => theme.color.reversedGrayText};
          `
        : css`
            background-color: ${({ theme }) => theme.color.grayAccentedBackground};
            color: ${({ theme }) => theme.color.bluePrimary};
          `)}
`;
