import { useIntl } from '@margobank/components/intl';
import { StickerIcon } from '@margobank/components/Sticker';
import { Body, Column, ErrorMessage, LinkButton, PrimaryButton, Subheading } from '@memobank/ui';

import { getUnverifiedEmail } from 'app/auth/helpers';
import { useRequestEmailUpdatedVerification } from 'app/auth/mutations';
import type { UserDTO } from 'app/auth/types';
import { useHelpBeacon } from 'components/help-beacon';

import ValidationCard from '../../_parts/ValidationCard';
import ValidationLayout from '../../_parts/ValidationLayout';

type Props = {
  error?: string;
  onConfirm: () => void;
  user: UserDTO;
};

const Intro = ({ error, onConfirm, user }: Props) => {
  const { openMessageBox } = useHelpBeacon();
  const { t } = useIntl();

  const [requestEmailUpdatedVerification] = useRequestEmailUpdatedVerification();

  const unverifiedEmail = getUnverifiedEmail(user);

  if (!unverifiedEmail) {
    throw Error('unverifiedEmail must be defined');
  }

  const handleConfirm = () => {
    requestEmailUpdatedVerification({ userId: user.id });
    onConfirm();
  };

  return (
    <ValidationLayout title={t('auth.validation.email.title')}>
      <ValidationCard>
        <StickerIcon icon="large/mail_with-rotating-arrows" size="x-large" variant="blue" />
        <Column spacing="s2">
          <Column spacing="s1">
            <Subheading>{t('auth.validation.email.intro.title')}</Subheading>
            <Body>{t('auth.validation.email.intro.content')}</Body>
          </Column>
          <Body>
            {t('auth.validation.email.intro.newEmail', {
              em: (chunk) => (
                <Body bold highlighted inline>
                  {chunk}
                </Body>
              ),
              email: unverifiedEmail,
            })}
          </Body>
        </Column>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <PrimaryButton onClick={handleConfirm}>
          {t('auth.validation.email.intro.verify')}
        </PrimaryButton>
        <LinkButton onClick={() => openMessageBox()}>
          {t('auth.validation.email.intro.signalError')}
        </LinkButton>
      </ValidationCard>
    </ValidationLayout>
  );
};

export default Intro;
