import type { ReactNode } from 'react';

import { useDevice } from '@margobank/components/device';
import { useIntl } from '@margobank/components/intl';
import Logo from '@margobank/components/Logo';
import type { Locale } from '@memobank/intl';
import { Heading, Row, Subheading } from '@memobank/ui';

import { useLocale } from 'app/auth/hooks';
import laptopImage from 'assets/images/laptop.jpg';
import fxDiscountImageEn from 'assets/images/login-fx-discount-en.png';
import fxDiscountImageFr from 'assets/images/login-fx-discount-fr.png';
import virtualCardsImageEn from 'assets/images/login-virtual-cards-en.png';
import virtualCardsImageFr from 'assets/images/login-virtual-cards-fr.png';
import vaultImage from 'assets/images/vault.jpg';
import UserDropdown from 'components/UserDropdown';

import {
  AbsoluteTextContainer,
  BlackButton,
  CenteredContent,
  ColumnLeft,
  ColumnRight,
  CoverImage,
  FullScreenRow,
  HeadingsColumn,
  PaddingWrapper,
  UserDropdownWrapper,
} from './_styled';

type Variant = 'fx-discount' | 'laptop' | 'vault' | 'virtual-cards';

type Props = {
  children: ReactNode;
  renderFooter?: () => ReactNode;
  variant: Variant;
  withUserDropdown?: boolean;
};

const getImage = (variant: Variant, locale: Locale) => {
  switch (variant) {
    case 'fx-discount':
      return locale === 'en' ? fxDiscountImageEn : fxDiscountImageFr;

    case 'laptop':
      return laptopImage;

    case 'virtual-cards':
      return locale === 'en' ? virtualCardsImageEn : virtualCardsImageFr;

    case 'vault':
    default:
      return vaultImage;
  }
};

const SideImageLayout = ({ children, renderFooter, variant, withUserDropdown }: Props) => {
  const { isScreenAtLeastL } = useDevice();
  const { t } = useIntl();
  const { locale } = useLocale();

  return (
    <FullScreenRow>
      <ColumnLeft>
        <PaddingWrapper>
          <Row alignItems="center" justify-content="space-between" width="100%">
            {/* Center the Logo */}
            <Row flex="1"></Row>
            <Logo variant="dark" />
            <Row flex="1" justifyContent="flex-end">
              {withUserDropdown && !isScreenAtLeastL && <UserDropdown variant="light" />}
            </Row>
          </Row>
          <CenteredContent>{children}</CenteredContent>
          {renderFooter && renderFooter()}
        </PaddingWrapper>
      </ColumnLeft>
      <ColumnRight hideFor={{ max: 'l' }}>
        {withUserDropdown && (
          <UserDropdownWrapper>
            <UserDropdown variant="transparent" />
          </UserDropdownWrapper>
        )}
        <CoverImage image={getImage(variant, locale)} />
        {variant === 'fx-discount' ? (
          <AbsoluteTextContainer alignItems="center" spacing="s4" textAlign="center">
            <HeadingsColumn maxWidth="large" paddingX="s8" spacing="s1">
              <Heading>{t('auth.login.fxDiscountFeature.title')}</Heading>
              <Subheading>{t('auth.login.fxDiscountFeature.description')}</Subheading>
            </HeadingsColumn>
            <BlackButton href={t('auth.login.fxDiscountFeature.buttonUrl')} target="_blank">
              {t('auth.login.fxDiscountFeature.buttonText')}
            </BlackButton>
          </AbsoluteTextContainer>
        ) : variant === 'virtual-cards' ? (
          <AbsoluteTextContainer alignItems="center" spacing="s4" textAlign="center">
            <HeadingsColumn maxWidth="large" paddingX="s8" spacing="s1">
              <Heading>{t('auth.login.virtualCardsFeature.title')}</Heading>
              <Subheading>{t('auth.login.virtualCardsFeature.description')}</Subheading>
            </HeadingsColumn>
            <BlackButton href={t('auth.login.virtualCardsFeature.buttonUrl')} target="_blank">
              {t('auth.login.virtualCardsFeature.buttonText')}
            </BlackButton>
          </AbsoluteTextContainer>
        ) : null}
      </ColumnRight>
    </FullScreenRow>
  );
};

export default SideImageLayout;
