import { Base64 } from 'js-base64';

import { createMutationHook, invalidateQueryHooks } from '@margobank/components/query-helpers';
import type { Locale } from '@memobank/intl';

import http, { USERS_SERVICE_PREFIX } from 'common/http';
import createMutationHookWith2FA from 'components/SecondFactorValidation/createMutationHookWith2FA';

import { useLoadIdentityProofs } from './queries';
import type {
  AnswerInvitationParamsDTO,
  TermsAcceptanceDTO,
  UpdatePasswordParamsDTO,
} from './types';

type UseAnswerInvitation = {
  answer: AnswerInvitationParamsDTO;
  invitationToken: string;
};

export const useAnswerInvitation = createMutationHook(
  async ({ answer, invitationToken }: UseAnswerInvitation) => {
    const { data } = await http.post(
      `${USERS_SERVICE_PREFIX}/invitations/${invitationToken}/answer`,
      answer,
    );
    return data;
  },
);

type UseConfirmTerm = {
  params: TermsAcceptanceDTO;
  userId: string;
};

export const useConfirmTerms = createMutationHook(async ({ params, userId }: UseConfirmTerm) => {
  const { data } = await http.put(
    `${USERS_SERVICE_PREFIX}/users/${userId}/terms_acceptance`,
    params,
  );
  return data;
});

type UseDeleteIdentityProof = {
  fileId: string;
  userId: string;
};

export const useDeleteIdentityProof = createMutationHook(
  async ({ fileId, userId }: UseDeleteIdentityProof) => {
    const { data } = await http.delete(
      `${USERS_SERVICE_PREFIX}/users/${userId}/identity_proof_candidates/${fileId}`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadIdentityProofs),
  },
);

type UseRequestEmailUpdatedVerification = {
  userId: string;
};

export const useRequestEmailUpdatedVerification = createMutationHook(
  async ({ userId }: UseRequestEmailUpdatedVerification) => {
    const { data } = await http.post(
      `${USERS_SERVICE_PREFIX}/users/${userId}/email_update/request_code`,
    );
    return data;
  },
);

type UseRequestPasswordReset = {
  email: string;
};

export const useRequestPasswordReset = createMutationHook(
  async ({ email }: UseRequestPasswordReset) => {
    const { data } = await http.post(`${USERS_SERVICE_PREFIX}/request_password_reset`, { email });
    return data;
  },
);

type UseRequestPhoneNumberUpdatedVerification = {
  userId: string;
};

export const useRequestPhoneNumberUpdatedVerification = createMutationHook(
  async ({ userId }: UseRequestPhoneNumberUpdatedVerification) => {
    const { data } = await http.post(
      `${USERS_SERVICE_PREFIX}/users/${userId}/phone_number_update/request_code`,
    );
    return data;
  },
);

type UseUpdateUserLocale = {
  locale: Locale;
};

export const useUpdateUserLocale = createMutationHook(async ({ locale }: UseUpdateUserLocale) => {
  const { data } = await http.put(`${USERS_SERVICE_PREFIX}/users/me/locale`, { locale });
  return data;
});

type UseUploadIdentityProof = {
  file: File;
  userId: string;
};

export const useUploadIdentityProof = createMutationHook(
  async ({ file, userId }: UseUploadIdentityProof) => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await http.post(
      `${USERS_SERVICE_PREFIX}/users/${userId}/identity_proof_candidates`,
      formData,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadIdentityProofs),
  },
);

type UseValidateIdentityProofs = {
  identityProofs: string[];
  userId: string;
};

export const useValidateIdentityProofs = createMutationHook(
  async ({ identityProofs, userId }: UseValidateIdentityProofs) => {
    const { data } = await http.put(`${USERS_SERVICE_PREFIX}/users/${userId}/identity_proofs`, {
      identityProofs,
    });
    return data;
  },
);

type UseUpdatePasswordWith2FA = {
  headers?: Record<string, string>;
  params: UpdatePasswordParamsDTO;
};

export const useUpdatePasswordWith2FA = createMutationHookWith2FA(
  async ({ headers, params }: UseUpdatePasswordWith2FA) => {
    const { newPassword, oldPassword } = params;
    const { data } = await http.put(
      `${USERS_SERVICE_PREFIX}/users/me/password`,
      {
        newPassword: Base64.encode(newPassword),
        oldPassword: Base64.encode(oldPassword),
      },
      { headers },
    );
    return data;
  },
);
