import type { ReactNode } from 'react';

import { useIntl } from '@margobank/components/intl';
import { FullPageModalHeader, FullPageModalLayout } from '@margobank/components/page';

import UserDropdown from 'components/UserDropdown';

type Props = {
  children: ReactNode;
  onPrevious?: () => void;
  subtitle?: string;
  title: string;
};

const Layout = ({ children, onPrevious, subtitle, title }: Props) => {
  const { t } = useIntl();

  return (
    <FullPageModalLayout
      headerElement={
        <FullPageModalHeader
          subtitle={subtitle}
          title={title}
          {...(onPrevious && {
            backButton: {
              label: t('common.back'),
              onClick: onPrevious,
            },
          })}
          rightComponent={<UserDropdown onlyLogout />}
        />
      }
    >
      {children}
    </FullPageModalLayout>
  );
};

export default Layout;
