export const CUSTOMER_ROUTE = {
  ACCOUNT: 'account',
  ACCOUNTING_MAPPINGS: 'accounting/mappings',
  ACCOUNTING_OPERATIONS: 'accounting/operations',
  ACCOUNTING_SUPPLIERS: 'accounting/suppliers',
  API: 'api',
  CARDS: 'cards',
  COLLECTIONS: 'collections',
  COMPANY_SETTINGS: 'company-settings',
  DASHBOARD: 'dashboard',
  DEBT_PURCHASE_FINANCING_DETAILS: 'financing/details/debt-purchase',
  DOCUMENTS: 'documents',
  FINANCING: 'financing',
  LOAN_FINANCING_DETAILS: 'financing/details',
  OUTGOING_COLLECTIONS: 'outgoing-collections',
  PAYMENT: 'payment',
  TERM_DEPOSITS: 'term-deposits',
  TRANSFER_REQUESTS: 'transfer-requests',
  USER_SETTINGS: 'user-settings',
  USERS: 'users',
} as const;
