import { ActionBar } from '@margobank/components/form';
import { useIntl } from '@margobank/components/intl';
import { useLoadingState } from '@margobank/components/Loader';
import Modal from '@margobank/components/Modal';
import { useSnackbar } from '@margobank/components/snackbar';
import { Body, Column, PrimaryButton, SecondaryButton, Subheading } from '@memobank/ui';

import { useHelpBeacon } from 'components/help-beacon';

type Props = {
  isOpen: boolean;
  obfuscatedPhoneNumber: string;
  onRequestClose: () => void;
  onResendSMS: () => Promise<any>;
};

const ResendPhoneNumberConfirmationModal = ({
  isOpen,
  obfuscatedPhoneNumber,
  onRequestClose,
  onResendSMS,
}: Props) => {
  const { openMessageBox } = useHelpBeacon();
  const { t } = useIntl();
  const { error, isLoading, watch } = useLoadingState();
  const { displaySuccess } = useSnackbar();

  const handleResendSMS = () => {
    watch(onResendSMS()).then(() => {
      displaySuccess(t('common.snackbar.textMessageSent'));
      onRequestClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} withCloseButton>
      <Column spacing="s3">
        <Column spacing="s1_5">
          <Subheading>{t('auth.confirmPhoneNumber.resendModal.title')}</Subheading>
          <Body>
            {t('auth.confirmPhoneNumber.resendModal.body1', {
              em: (chunk) => (
                <Body bold highlighted inline>
                  {chunk}
                </Body>
              ),
              phoneNumber: obfuscatedPhoneNumber,
            })}
          </Body>
          <Body>{t('auth.confirmPhoneNumber.resendModal.body2')}</Body>
        </Column>
        <ActionBar error={error}>
          <SecondaryButton
            onClick={() =>
              openMessageBox({
                subject: t('common.helpBeacon.resendPhoneNumber.subject'),
                text: t('common.helpBeacon.resendPhoneNumber.text', {
                  phoneNumber: obfuscatedPhoneNumber,
                }),
              })
            }
          >
            {t('auth.confirmPhoneNumber.resendModal.reportWrongNumber.text')}
          </SecondaryButton>
          <PrimaryButton isLoading={isLoading} onClick={handleResendSMS}>
            {t('auth.confirmPhoneNumber.resendModal.submit')}
          </PrimaryButton>
        </ActionBar>
      </Column>
    </Modal>
  );
};

export default ResendPhoneNumberConfirmationModal;
