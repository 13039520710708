import type { ReactNode } from 'react';
import { SubmissionError } from 'redux-form';

import { FILE_TYPE } from '@margobank/components/common/constants';
import { DocumentCard } from '@margobank/components/domain/documents';
import FileDropzone from '@margobank/components/FileDropzone';
import { createForm, handleFormError } from '@margobank/components/form';
import { useIntl } from '@margobank/components/intl';
import { Column, ErrorAlert, Heading, PrimaryButton, Subheading } from '@memobank/ui';

import SideImageLayout from 'app/auth/_parts/SideImageLayout';
import {
  useDeleteIdentityProof,
  useUploadIdentityProof,
  useValidateIdentityProofs,
} from 'app/auth/mutations';
import { useLoadIdentityProofs } from 'app/auth/queries';
import type { UserDTO } from 'app/auth/types';

const Form = createForm('auth.uploadIdentityProof');

type Props = {
  onConfirm: () => void;
  renderFooter?: () => ReactNode;
  user: UserDTO;
};

const UploadIdentityProof = ({ onConfirm, renderFooter, user }: Props) => {
  const { t } = useIntl();

  const [identityProofs, { LoadingState }] = useLoadIdentityProofs({ userId: user.id });
  const [deleteIdentityProof] = useDeleteIdentityProof();
  const [uploadIdentityProof] = useUploadIdentityProof();
  const [validateIdentityProofs] = useValidateIdentityProofs();

  const handleDeleteFile = ({ id }) => {
    return deleteIdentityProof({ userId: user.id, fileId: id });
  };

  const handleDropFile = (file: File) => {
    return uploadIdentityProof({ userId: user.id, file });
  };

  const handleSubmit = () => {
    if (!identityProofs?.length) {
      throw new SubmissionError({ _error: t('auth.uploadIdentityProof.error') });
    }
    const identityProofIDs = identityProofs.map((proof) => proof.id);
    return validateIdentityProofs({ userId: user.id, identityProofs: identityProofIDs }).then(
      () => onConfirm(),
      (error) => handleFormError(error),
    );
  };

  return (
    <SideImageLayout renderFooter={renderFooter} variant="laptop" withUserDropdown>
      <Column alignItems="center" spacing="s3">
        <Column spacing="s2">
          <Heading>{t('auth.uploadIdentityProof.title')}</Heading>
          <Subheading light subdued>
            {t('auth.uploadIdentityProof.subtitle')}
          </Subheading>
        </Column>
        <Form onSubmit={handleSubmit}>
          {(form) => (
            <Column spacing="s3" textAlign="left">
              {form.error && <ErrorAlert message={form.error} />}
              <Column spacing="s2">
                {typeof identityProofs === 'undefined' ? (
                  <LoadingState />
                ) : (
                  <>
                    {identityProofs.map((identityProof) => (
                      <DocumentCard
                        key={identityProof.id}
                        document={identityProof}
                        onDeleteClick={handleDeleteFile}
                        withSticker
                      />
                    ))}
                    {identityProofs.length < 2 && (
                      <FileDropzone
                        // We add a `key` prop to reset the previously uploaded file, as it will
                        // already be displayed in the list above.
                        key={identityProofs.length}
                        onDrop={handleDropFile}
                        placeholder={t('auth.uploadIdentityProof.dropzonePlaceholder')}
                        suggestedFileTypes={[FILE_TYPE.IMAGE]}
                      />
                    )}
                  </>
                )}
              </Column>
              <PrimaryButton isLoading={form.submitting} type="submit">
                {t('common.actions.continue')}
              </PrimaryButton>
            </Column>
          )}
        </Form>
      </Column>
    </SideImageLayout>
  );
};

export default UploadIdentityProof;
