import 'react-app-polyfill/stable';
import '@margobank/components/style/fonts.css';

import { createClient as createPrismicClient } from '@prismicio/client';
import { PrismicProvider } from '@prismicio/react';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import AccessibleFocusRing from '@margobank/components/common/AccessibleFocusRing';
import { DeviceProvider } from '@margobank/components/device';
import { DownloadProvider } from '@margobank/components/download';
import {
  ChunkLoadErrorBoundary,
  SentryErrorBoundary,
} from '@margobank/components/error-boundaries';
import { FeaturesProvider } from '@margobank/components/feature-toggles';
import { ConfirmationModalProvider } from '@margobank/components/Modal';
import { SnackbarProvider } from '@margobank/components/snackbar';
import { ViewerModalProvider } from '@margobank/components/Viewer';
import { ThemeProvider } from '@memobank/style';

import App from 'app/App';
import { useAuthStore } from 'app/auth/hooks';
import { selectUser } from 'app/auth/selectors';
import BackNavigationProvider from 'common/BackNavigationProvider';
import { AVAILABLE_FEATURE } from 'common/constants';
import {
  ENVIRONMENT,
  GOOGLE_TAG_MANAGER_ID,
  PRISMIC_API_ENDPOINT,
  RELEASE,
  SENTRY_DSN,
} from 'common/env';
import http, { setupHttpHeadersFromState } from 'common/http';
import IntlProvider from 'common/IntlProvider';
import queryClient from 'common/queryClient';
import store from 'common/store';
import type { AvailableFeature } from 'common/types';
import { FingerprintProvider } from 'components/fingerprint';
import { GTMProvider } from 'components/google-tag-manager';
import HelpBeaconProvider from 'components/help-beacon/HelpBeaconProvider';
import { Router } from 'components/routes';
import { SecondFactorValidationProvider } from 'components/SecondFactorValidation';
import SuspenseWithErrorBoundary from 'components/SuspenseWithErrorBoundary';
import theme from 'style/theme';

const history = createBrowserHistory();
const rootElement = document.getElementById('root');

const { getState: getAuthState } = useAuthStore;

if (!rootElement) {
  throw Error("Could not find 'root' element in DOM");
}

setupHttpHeadersFromState(() => store.getState(), getAuthState);

const sentryOptions = {
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: RELEASE,
};

const getSentryContext = () => {
  const state = store.getState();
  const { id: userId = undefined } = selectUser(state) || {};
  const locale = getAuthState().getLocale();

  return { locale, userId };
};

const initialGtmDataLayer = {
  environment: ENVIRONMENT,
  release: RELEASE,
};

const availableFeatures =
  ENVIRONMENT === 'production' || ENVIRONMENT === 'sandbox' ? [] : Object.values(AVAILABLE_FEATURE);

const prismicClient = createPrismicClient(PRISMIC_API_ENDPOINT);

// Force some features to be enabled for everyone
export const activeFeatures: AvailableFeature[] = [
  AVAILABLE_FEATURE.DISABLE_COLLECTION_EXPORT_QUICK_ACTION,
  AVAILABLE_FEATURE.OUTGOING_RTGS,
  AVAILABLE_FEATURE.SWEEP_TRANSFER_RULES,
  AVAILABLE_FEATURE.SWIFT_PRICING_PROMOTION,
  ...(ENVIRONMENT === 'sandbox' ? [AVAILABLE_FEATURE.SANDBOX] : []),
];

const root = createRoot(rootElement);
root.render(
  <PrismicProvider client={prismicClient}>
    <SentryErrorBoundary getContext={getSentryContext} options={sentryOptions}>
      <ChunkLoadErrorBoundary>
        <ThemeProvider theme={theme}>
          <AccessibleFocusRing>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <Router history={history}>
                  <SnackbarProvider>
                    <FeaturesProvider
                      activeFeatures={activeFeatures}
                      availableFeatures={availableFeatures}
                    >
                      <IntlProvider>
                        <GTMProvider
                          gtmId={GOOGLE_TAG_MANAGER_ID}
                          initialDataLayer={initialGtmDataLayer}
                        >
                          <FingerprintProvider>
                            <DeviceProvider>
                              <ConfirmationModalProvider>
                                <DownloadProvider get={http.get}>
                                  <SuspenseWithErrorBoundary>
                                    <HelpBeaconProvider>
                                      <BackNavigationProvider>
                                        <SecondFactorValidationProvider>
                                          <ViewerModalProvider>
                                            <App />
                                          </ViewerModalProvider>
                                        </SecondFactorValidationProvider>
                                      </BackNavigationProvider>
                                    </HelpBeaconProvider>
                                  </SuspenseWithErrorBoundary>
                                </DownloadProvider>
                              </ConfirmationModalProvider>
                            </DeviceProvider>
                          </FingerprintProvider>
                        </GTMProvider>
                      </IntlProvider>
                    </FeaturesProvider>
                  </SnackbarProvider>
                </Router>
              </Provider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AccessibleFocusRing>
        </ThemeProvider>
      </ChunkLoadErrorBoundary>
    </SentryErrorBoundary>
  </PrismicProvider>,
);
