import { useIntl } from '@margobank/components/intl';
import { Body, Icon, IconRow } from '@memobank/ui';

const CustomersMenuTriggerContent = () => {
  const { t } = useIntl();

  return (
    <IconRow flex="1" justifyContent="space-between" spacing="s1">
      <Body>{t('customer.userDropdown.companySwitch')}</Body>
      <Icon icon="medium/chevron_right_small" subdued />
    </IconRow>
  );
};

export default CustomersMenuTriggerContent;
