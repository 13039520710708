import { useIntl } from '@margobank/components/intl';
import Loader from '@margobank/components/Loader';
import { Body, Row, Sticker } from '@memobank/ui';

import SecondFactorValidationLayout from './SecondFactorValidationLayout';

type Props = {
  title: string;
};

const SecondFactorValidationApproved = ({ title }: Props) => {
  const { t } = useIntl();

  return (
    <SecondFactorValidationLayout
      body={t('auth.secondFactorValidation.body')}
      footerElement={
        <Row alignItems="start" spacing="s1_5">
          <Loader noDelay noPadding />
          <Body subdued>{t('auth.secondFactorValidation.approved')}</Body>
        </Row>
      }
      stickerElement={<Sticker sticker="x-large/push-authentication" />}
      title={title}
    />
  );
};

export default SecondFactorValidationApproved;
