import { lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import { useDebounce, useMount } from 'react-use';
import reportWebVitals from 'reportWebVitals';

import { SessionExpiration } from '@margobank/components/domain/auth';
import { FormGuardProvider } from '@margobank/components/form';
import { useIntl } from '@margobank/components/intl';
import { useLoadingState } from '@margobank/components/Loader';

import { keepAlive, loadUser, logout } from 'app/auth/actions';
import { AUTH_ERROR } from 'app/auth/constants';
import { selectSessionToken, selectUser } from 'app/auth/selectors';
import { useDispatch, useSelector } from 'common/store';
import { useGTM } from 'components/google-tag-manager';
import { useHelpBeacon } from 'components/help-beacon';
import {
  CustomerRoute,
  GrantingsRoute,
  PrivateHomeRedirect,
  PrivateRoute,
  Route,
  usePagePath,
} from 'components/routes';

const AccountingExportInformation = lazy(
  () => import('./legacy-customer/accounting/ExportInformation'),
);
const Activation = lazy(() => import('./auth/Activation'));
const CRSFatca = lazy(() => import('./legacy-customer/CRSFatca'));

const CustomerApp = lazy(() => import('./customer/CustomerApp'));
const ExportInformation = lazy(() => import('./legacy-customer/ExportInformation'));
const GrantingsApp = lazy(() => import('./grantings/GrantingsApp'));
const Callback = lazy(() => import('./grantings/Callback'));

const Invitation = lazy(() => import('./auth/Invitation'));
const Login = lazy(() => import('./auth/Login'));
const MandateSignature = lazy(
  () => import('./legacy-customer/OutgoingCollections/MandateSignature'),
);
const MobileUnpairing = lazy(() => import('./auth/MobileUnpairing'));
const PasswordForgotten = lazy(() => import('./auth/PasswordForgotten'));
const PasswordReset = lazy(() => import('./auth/PasswordReset'));
const Setup = lazy(() => import('./legacy-customer/Setup'));
const ThirdPartyAuthorization = lazy(() => import('./auth/ThirdPartyAuthorization'));
const Welcome = lazy(() => import('./legacy-customer/Welcome'));

const PAGE_PATH_DEBOUNCE_DELAY_MS = 1000;

const App = () => {
  const dispatch = useDispatch();
  const gtm = useGTM();
  const { close: closeHelpBeacon, setUser: setHelpBeaconUser } = useHelpBeacon();
  const { locale, t } = useIntl();
  const { LoadingState, watch } = useLoadingState();
  const pagePath = usePagePath();
  const [wereWebVitalsSent, setWereWebVitalsSent] = useState(false);
  const sessionToken = useSelector(selectSessionToken);
  const user = useSelector(selectUser);

  useMount(() => {
    if (sessionToken) {
      watch(dispatch(loadUser())).catch(() => {});
    }
  });

  // Debounce is required to get the canonical pagePath
  useDebounce(() => gtm.sendPageView(pagePath), PAGE_PATH_DEBOUNCE_DELAY_MS, [pagePath]);

  useDebounce(
    () => {
      // Web Vitals should only be reported once per page load
      // See https://github.com/GoogleChrome/web-vitals#basic-usage
      if (!wereWebVitalsSent) {
        reportWebVitals((metrics) => gtm.sendWebVitals(metrics, pagePath));
        setWereWebVitalsSent(true);
      }
    },
    PAGE_PATH_DEBOUNCE_DELAY_MS,
    [pagePath, wereWebVitalsSent],
  );

  useEffect(() => {
    gtm.pushDataLayer({ isLoggedIn: !!user });
  }, [gtm, user]);

  useEffect(() => {
    setHelpBeaconUser(user ?? null);
  }, [setHelpBeaconUser, user]);

  const handleExpire = () => {
    closeHelpBeacon();
    return dispatch(logout(AUTH_ERROR.SESSION_EXPIRED)).catch(() => {});
  };

  const handleLogout = () => {
    return dispatch(logout()).catch(() => {});
  };

  if (sessionToken && !user) {
    return <LoadingState />;
  }

  return (
    <>
      <Helmet titleTemplate={t('client.meta.title.template')}>
        <html lang={locale} />
      </Helmet>
      <FormGuardProvider isDisabled={!user}>
        {user && (
          <SessionExpiration
            onExpire={handleExpire}
            onKeepAlive={() => dispatch(keepAlive()).then(({ value }) => value)}
            onLogout={handleLogout}
          />
        )}
        <Switch>
          {/* Home: redirect to either onboarding or customer app */}
          <PrivateHomeRedirect exact path="/" />

          {/* Grantings */}
          <GrantingsRoute
            component={GrantingsApp}
            path="/onboarding/:grantingFileHash?"
            title={t('client.meta.title.onboarding')}
          />

          {/* Activation flow for an onboarding owner */}
          <Route
            component={Activation}
            path="/activation/:token"
            title={t('client.meta.title.activation')}
          />

          {/* Activation flow for an invited user */}
          <Route
            component={Invitation}
            path="/invitation/:token"
            title={t('client.meta.title.invitation')}
          />

          {/* Mandate signature for a debtor */}
          <Route
            component={MandateSignature}
            path="/mandate/:token"
            title={t('client.meta.title.mandateSignature')}
          />

          {/* Login screen */}
          <Route
            component={Login}
            description={t('client.meta.description.login')}
            path="/login"
            title={t('client.meta.title.login')}
          />

          <Route
            component={PasswordForgotten}
            path="/password/forgotten"
            title={t('client.meta.title.forgottenPassword')}
          />

          <Route
            component={PasswordReset}
            path="/password/reset/:token"
            title={t('client.meta.title.resetPassword')}
          />

          <Route
            component={MobileUnpairing}
            path="/unpair"
            title={t('client.meta.title.mobileRepairing')}
          />

          <Route component={Callback} path="/callback" title={t('client.meta.title.callback')} />

          {/* Third party authorization flows */}
          <PrivateRoute
            component={ThirdPartyAuthorization}
            description={t('client.meta.description.thirdPartyAuthorization')}
            path="/authorize"
            title={t('client.meta.title.thirdPartyAuthorization')}
          />

          {/* Redirect URL from YouSign modal */}
          <Route component={() => null} path="/you-sign-callback" />

          {/* Setup, when we don't have any customer yet */}
          <PrivateRoute
            component={Setup}
            path="/setup/:customerHash?"
            title={t('client.meta.title.setup')}
            withSetupSteps
            withVerificationSteps
          />

          {/* Welcome page after successful onboarding */}
          <PrivateRoute
            component={Welcome}
            path="/welcome/:grantingFileHash"
            title={t('client.meta.title.welcome')}
          />

          {/* Export landing page */}
          <CustomerRoute
            component={ExportInformation}
            path="/:customerHash/export/:token"
            title={t('client.meta.title.export')}
          />

          {/* Accounting export landing page */}
          <CustomerRoute
            component={AccountingExportInformation}
            path="/:customerHash/accounting/exports/:exportId/files/:exportFileId"
            title={t('client.meta.title.accountingExport')}
          />

          {/* The CRS-Fatca Self-certification flow */}
          <CustomerRoute
            component={CRSFatca}
            path="/:customerHash/crs-fatca"
            title={t('client.meta.title.crsFatca')}
          />

          {/* Customer */}
          <CustomerRoute component={CustomerApp} path="/:customerHash" />
        </Switch>
      </FormGuardProvider>
    </>
  );
};

export default App;
